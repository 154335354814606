:root {
  --shelfcol: #48371b;
}

.bookshelf {
  margin-top: 1vw;
  width: 42.5vw;
  height: 24.5vw;
  /* background-image: url('../images/wood2.jpg');
  background-repeat: repeat;
  background-size: 100% 100%; */
  background-color: #5e470d; 
  border: 3px solid #221a04;
  border-radius: 5px;
}

.innerbox-container{
  background-color: #d0c19c;
  height: 100%;
  width: 100%;
}
.innerboxline1{ /* bottom right */
  background-color: #2b2111;
  clip-path: polygon(94.7% 75%, 95% 75%, 99.7% 94.25%, 100% 94.25%);
  aspect-ratio: 3.41;
  width: 41.7%;
  position: absolute;
}
.innerboxline2{ /* vertical */
  background-color: #2b2111;
  clip-path: polygon(94.7% 75%, 95% 75%, 94.7% 0%, 95% 0%);
  aspect-ratio: 3.41;
  width: 41.7%;
  position: absolute;
}
.innerboxline3{ /* horizontal */
  background-color: #2b2111;
  clip-path: polygon(0% 75%, 0% 75.3%, 95% 75%, 95% 75.3%);
  aspect-ratio: 3.41;
  width: 41.7%;
  position: absolute;
}



/* Books */

.books-row {
  position: relative;
  left: 0.5%;
  top: 5.1%;
}
.card {
  /* Base card layer */
  position: absolute;
  height: 11.7vw;
  width: 10%;
  left: -.5%;
  top: -.7vw;
}
.card .base {
  z-index: 6;
  height: 100%;
  width: 100%;
  /* background-color: #362a15; */
  /* background-image: url('../images/leather.jpg');
  background-repeat: repeat;
  background-size: 100% 100%; */
  background-color: #6e4e19;
                  /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(3% 1%, 77% 1%, 77% 99%, 3% 99%);
  transition: 0.3s clip-path ease-in;
  display: flex;
  display: -webkit-flex;
  position: absolute;
}
.card .base-outline {
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(29, 18, 3);
  clip-path: polygon(0 0, 80% 0, 80% 100%, 0% 100%);
  transition: 0.3s clip-path ease-in;
}

.card .textE{
  color: rgb(0, 0, 0); 
  font-family: "Papyrus";
  font-weight: bold;
  font-size: 1.1vw;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap; 
  transition: 0.3s ease-in;
  align-self: center;
  justify-self: center;
}

.card .top { /* this will have the lined paper texture */
  z-index: 4;
  background-image: url("../images/lines.png");
  background-repeat: repeat;
  background-size: 10% 10%;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff3cc;
                  /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(4% 1%, 77% 1%, 77% 1%, 4% 1%);
  transition: 0.3s clip-path ease-in;
}
.card .top-outline {
  z-index: 3;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(29, 18, 3);
  clip-path: polygon(0 0, 80% 0, 80% 0, 0 0);
  transition: 0.3s clip-path ease-in;
}


.card .left {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #423209;
  /* background-image: url('../images/leather.jpg');
  background-repeat: repeat;
  background-size: 100% 100%; */
  /*                 right, bottom left, top left                       */
  clip-path: polygon(0% 0%, 0% 98%, 0% 0%);
  transition: 0.3s clip-path ease-in;
}
.card .left-outline{
  z-index: -1;
  position: absolute;
  background-color: rgb(29, 18, 3);
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 0 100%, 0 0);
  transition: 0.3s clip-path ease-in;
}