.corkborder {
  width: 80vw;
  height: 19.8vw;
  border: 3px solid var(--bordercol);
  margin: 2.12vw 0 0 1vw;
}
.corkboard {
  background-image: url("../images/cork-texture.jpg");
  background-repeat: repeat;
  background-size: 50%;
  width: 100%;
  height: 100%;
  /*filter: blur(.6px);*/

  align-items: center;
  justify-content: space-evenly;
  
}
.pin {
  z-index: 1;
  position: absolute;
  margin-top: 1%;
  margin-left: 6%;
}
.pin-head {
  position: absolute;
  border-radius: 50%;
  width: .8vw;
  aspect-ratio: 1;
  left: 0.3vw;
  top: 0vw;
}
.pin-base {
  position: absolute;
  opacity: 75%;
  border-radius: 50%;
  width: 1vw;
  aspect-ratio: 1;
}

.paper {
  height: 18vw;
  background-color: #f5f5f5;
  border: 1px solid white inset;
  aspect-ratio: 58/83;
  box-shadow: 0px 4px 4px #00000040;
  align-items: center;
}
.paper-title {
  font-family: "Papyrus";
  font-size: 1.25vw;
  margin: 2%;
  margin-top: 25%;
}
.paper-text {
  text-align: center;
  margin-top: 5%;
  width: 85%;
  color: #7e0a0a;
  font-family: "Freestyle Script";
  font-size: .7vw;
  line-height: normal;
}

.postits {
  margin-left: 1vw;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.postit {
  background-color: #fafa7f;
  height: 100%;
  width: 100%;

}

.postit-wrap {
  height: 25%;
  aspect-ratio: 1;
  filter: drop-shadow(0px 4px 4px #00000040); 
}

.postit-image {
  height: 80%;
}