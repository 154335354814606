.map-pin-top:hover {
  border-radius: 5px;
  background-color: white;
  border: 2px solid var(--pin-color);
  background-color: rgba(255, 255, 255, 0.68);
  width: fit-content;
  padding: .3vw;
  text-decoration: none !important;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  height: .8vw;
}
.grey-pin:hover {
  border: 2px solid rgb(129, 129, 129);
}

.map-pin-text {
  visibility: hidden;
  font-family: "Papyrus";
  font-weight: bold;
  font-size: .9em;
  color: black;
  white-space: nowrap;
  margin-top: 2.5vh;
}
.map-pin-top:hover .map-pin-text{
  visibility: visible;
}