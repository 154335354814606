:root {
  --pin-color: rgb(170, 21, 21);
}


.portfolio-container {
  position: relative;
  width: 52vw;
  height: 25.5vw;
  margin-top: .6vw;
  display: flex;
  align-items: center;
}
.map-wrapper {
  height: 96%;
  border-radius: 5px;
  display: flex;
  padding: 1%;
}
.map {
  height: 100%;
  border-radius: 5px;
}

.tl-tape {
  position: absolute;
  height: 3.5%;
  width: 6%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(-45deg);
  left: -.5%;
  top: 3.5%;
}

.tr-tape {
  position: absolute;
  height: 3.5%;
  width: 6%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(45deg);
  left: 84.5%;
  top: 3.5%;
}

.bl-tape {
  position: absolute;
  height: 3.5%;
  width: 6%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(45deg);
  left: -.5%;
  top: 92.7%;
}

.br-tape {
  position: absolute;
  height: 3.5%;
  width: 6%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotate(-45deg);
  left: 84.5%;
  top: 92.7%;
}

.map-title {
  position: absolute;
  font-family: "Papyrus";
  font-weight: bold;
  font-size: 1.5em;
  color: var(--pin-color);
  left: 4%;
  top: 6%;
  text-decoration: underline;
  /* backdrop-filter: blur(10px); */
  /* border: 3px solid var(--pin-color); */
  text-shadow: 0 0 10px #ffffff;
}

.map-pin {
  position: absolute;
}
.map-pin-top {
  transition: 200ms;
  position: absolute;
  height: .75vw;
  width: .75vw;
  background-color: var(--pin-color);
  border-radius: 25px;
  bottom: 0;
  z-index: 2;
}
.grey-pin {
  background-color: rgb(129, 129, 129);
}

.map-pin-needle {
  position: absolute;
  height: 0.4vw;
  width: 0.1vw;
  background-color: rgb(163, 163, 163);
  border-radius: 25px;
  left: .33vw;
  bottom: -.35vw;
}

.region-label {
  position: absolute;
  color: white;
  font-size: .75vw;
  font-weight: bold;
  font-family: "Papyrus";
  font-style: italic;
  text-shadow: 0 0 10px #000000, 0 0 10px #000000;
}