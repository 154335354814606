:root {
  --bordercol: #7e621a;
}

.App {
  background-color: #4D4A42;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  align-items: center;
}
.mobile {
  display: none !important;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-top: 40%;
  text-align: center;
}
.mobile-text-1 {
  font-size: 3vmax;
}
.mobile-text-2 {
  margin-top: 0;
  color:rgba(0, 0, 0, 0.6);
  font-size: 2.5vmax;
}
.mobile-text-3 {
  color:rgba(0, 0, 0, 0.6);
  font-size: 2vmax;
}

@media (max-width: 600px) { /* applies when the viewport is <= 600px*/
  .App, .pin, .pin-head, .pin-base {
    display: none !important;
  }
  .mobile {
    display: block !important;
    height: 100%;
    width: 100%;
  }
}
.hunp {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.open-paper-bg {
  z-index: 20;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  animation: fade-in-select 1s;
}
.select-wrapper {
  z-index: 21;
  position: absolute;
  animation: fade-in-select 1s;
}

.select-view {
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  background: white;
}

.book-divider {
  height: 40vw;
  width: 2vw;
  background-color: #6e4e19;
  z-index: 0;
}

.paper-border {
  z-index: 20;
  position: absolute;
  height: 44vw;
  width: 33vw;
  border-radius: 5px;
  background: transparent;
  border: 2vw solid white;
  fill: transparent;
  pointer-events: none;
}
.book-border {
  height: 39.7vw;
  width: 31.1vw; /*31.25vw;*/
  border: 1vw solid white;
}

.paper-specs {
  border: 5px solid white;
  height: 46vw;
  width: 36.3vw;
  overflow: hidden;
}

.book-specs {
  outline: 10px solid rgb(29, 18, 3);
  height: 41.5vw;
  width: 33vw;
}

@keyframes fade-in-select {
  0%, 60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}