/* On load */
.paper {
  transform: translateY(-120%);
  animation: paper-drop 0.5s forwards ease-in-out;
}
@keyframes paper-drop {
  0% { transform: translateY(-120%) }
  100% { transform: translateY(0%) }
}

.pin {
  animation: pin-load 0.25s forwards ease-in;
  transform: scale(200%);
  visibility: hidden;
}
@keyframes pin-load {
  0% { 
    visibility: visible;
  }
  100% {
    visibility: visible;
    transform: scale(100%) 
  }
}





/* On hover */

.paper:hover {
  filter: brightness(100%); /* bug in css - changes parent */
  box-shadow: 0px 10px 10px #00000040;
  cursor: grab;
}

.paper-wrapper:hover .pin-head {
  transform: scale(125%);
}
.paper-wrapper:hover .pin-base {
  transform: scale(125%);
  filter: drop-shadow(-8px 4px 4px #000000a4);
}

.postit:hover {
  clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 85%, 100% 0%);
  cursor: grab;
}

.postit-wrap:hover {
  filter: drop-shadow(0px 10px 10px #00000040); 
}
