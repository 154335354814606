.card:hover{
  cursor: grab;
}
.card:hover .base {
                  /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(24% 26.5%, 96% 26.5%, 77% 99%, 3% 99%);
}
.card:hover .base-outline {
                  /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(21% 25.5%, 99% 25.5%, 80% 100%, 0% 100%);
}
.card:hover .textE{
  transform: rotate(185.5deg) translate(-0.8vw, -1.5vw);
  font-size: .8vw;
}
.card:hover .top {
                /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(4% 1%, 77% 1%, 96% 26.5%, 25% 26.5%);
}
.card:hover .top-outline {
                  /* top left, top right, bottom right, bottom left*/
  clip-path: polygon(0 0, 80% 0, 99% 25.5%, 21% 25.5%);
}
.card:hover .left {
  clip-path: polygon(21% 25.5%, 3% 99%, 3% 1%);
}
.card:hover .left-outline{
  clip-path: polygon(24% 26.5%, 0% 100%, 0% 0%);
}