.painting-container {
  z-index: 2;
  padding: .5vw 0 0 0;
  width: 16vw;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
}
.painting {
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(80%);
}
.painting-border {
  z-index: 2;
  width: 92%;
  height: 92%;
  margin-top: 10%;
  border: 3px solid var(--bordercol);
  box-shadow: 0px 4px 4px #00000040;
  background-color: var(--bordercol);
}

.strings-container {
  position: relative;
  height: 27%;
}

.string1 {
  height: 40%;
  left: 7.75vw;
  position: absolute;
  top: 2px;
}

.string2 {
  height: 40%;
  left: 1.75vw;
  position: absolute;
  top: 2px;
}

.ellipse {
  background-color: #3d3d3d;
  border-radius: 3px;
  height: .4vw;
  aspect-ratio: 1;
  left: 7.45vw;
  position: absolute;
}


.socials-container {
  margin-left: .5vw;
  width: 15vw;
  height: 5vw;
  display: -webkit-flex; /* Safari 9+ support */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* Adding these properties to ensure flex children behave correctly */
  flex-grow: 1;
  min-width: 0;
}
.social-wrap {
  z-index: 0;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.socials-string {
  position: absolute;
  height: 5%;
  width: 2px;
  background-color: #d9d9d9;
}

.logo {
  margin-top: 2vh;
  z-index: 1;
  border: 3px solid var(--bordercol);
  box-shadow: 0px 3px 3px #00000040;
  height: 3.6vw;
  background-color: black;
}
.gmail-container{
  aspect-ratio: 1;
  background-color: #d9d9d9;
}
.gmail {
  height: 60%;
}

