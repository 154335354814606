:root {
  --highlight: rgb(255, 255, 0);
}

/* Contact */
.painting {
  animation: fade-in 1s;
  opacity: 1;
  transition: opacity 0.4s; /* smooth transition both on hover and off */
}

.painting:hover {
  opacity: 0.6;
}

.painting-border {
  animation: vertical-drop-painting 1.7s ease-in-out;
}

@keyframes vertical-drop-painting {
  0%, 10% { transform: translateY(-12%) }
  40% { transform: translateY(6%) }
  70%, 100% { transform: translateY(0%) }
}

.string1 {
  animation: right-string 1.7s ease-in-out;
}
@keyframes right-string {
  0%, 10% { 
    transform: rotate(-15deg) translateY(-45%);
  }
  40% { 
    transform: rotate(10deg) translateY(35%);
  }
  70%, 100% { 
    transform: rotate(0deg) translateY(0%);
  }
}

.string2 {
  animation: left-string 1.7s ease-in-out;
}

@keyframes left-string {
  0%, 10% { 
    transform: rotate(15deg) translateY(-45%);
  }
  40% { 
    transform: rotate(-10deg) translateY(35%);
  }
  70%, 100% { 
    transform: rotate(0deg) translateY(0%);
  }
}
@keyframes fade-in {
  0% { opacity: 0}
  100% { opacity: 1}
}

.social-wrap:hover .logo {
  filter: brightness(70%);
  animation: vertical-shaking 1s;
}
@keyframes vertical-shaking {
  0% { transform: translateY(0) }
  25% { transform: translateY(15%) }
  50% { transform: translateY(-15%) }
  75% { transform: translateY(15%) }
  100% { transform: translateY(0) }
}

.logo-onload {
 animation: vertical-drop 1.7s forwards;
}
.string-onload {
  animation: hide-string 1.7s;
}

@keyframes vertical-drop {
  0%, 15% { transform: translateY(-175%) }
  43% { transform: translateY(20%) }
  71% { transform: translateY(-10%) }
  100% { transform: translateY(0%) }
}
@keyframes hide-string {
  0%, 30% { visibility: hidden;}
  100% { visibility: visible; }
}